<template>
  <div id="app">
    <div class="container">
      <div class="col-md-10 offset-md-1">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <form id="ticketSubmit" class="w-100 p-3" @submit.prevent="handleSubmit(onSubmit)" enctype="multipart/form-data" method="POST">
              <div class="row">
                <div class="form-group col-md-12">
                    <label for="first_name">First Name <span class="text-danger">*</span></label>
                    <ValidationProvider name="first_name" rules="required|alpha_spaces" v-slot="{ errors }">
                        <input v-model="form['first_name']" name="first_name" class="form-control" type="text">
                        <span class="text-danger">{{ sentTicket == true ? '' : errors[0] }}</span>
                    </ValidationProvider>
                </div>
                <div class="form-group col-md-12">
                    <label for="last_name">Last Name <span class="text-danger">*</span></label>
                    <ValidationProvider name="last_name" rules="required|alpha_spaces" v-slot="{ errors }">
                        <input v-model="form['last_name']" name="last_name" class="form-control" type="text">
                        <span class="text-danger">{{ sentTicket == true ? '' : errors[0] }}</span>
                    </ValidationProvider>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-6">
                    <label for="email">Email <span class="text-danger">*</span></label>
                    <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
                        <input v-model="form['email']" name="Email" class="form-control" type="email">
                        <span class="text-danger">{{ sentTicket == true ? '' : errors[0] }}</span>
                    </ValidationProvider>
                </div>
                <div class="form-group col-md-6">
                  <label for="hpno">Mobile Number <span class="text-danger">*</span></label>
                    <ValidationProvider name="mobile" rules="required" v-slot="{ errors }">
                          <vue-tel-input pattern="^[0-9\-\+\s\(\)]*$"  v-on:country-changed="countryChanged" v-model="form['hpno']" :maxLen="15"></vue-tel-input>
                        <span class="text-danger">{{ sentTicket == true ? '' : errors[0] }}</span>
                    </ValidationProvider>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-12">
                  <label for="helptopic">Help Topic <span class="text-danger">*</span></label>
                    <ValidationProvider name="helptopic" rules="required|numeric" v-slot="{ errors }">
                        <select class="form-control" name="helptopic" v-model="form['helptopic']">
                          <option v-for="ht in hardcodedHelpTopic" :key=ht.id :value=ht.id>{{ht.topic}}</option>
                        </select>
                        <span class="text-danger">{{ sentTicket == true ? '' : errors[0] }}</span>
                    </ValidationProvider>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-12">
                  <label for="subject">Subject <span class="text-danger">*</span></label>
                    <ValidationProvider name="subject" :rules="{ max: 60, required: true,regex: /^([a-zA-Z0-9$&+,:;=?@#|_\]\['.^*()%!-/{}`~ ])*$/ }" v-slot="{ errors }">
                        <input v-model="form['subject']" name="Subject" class="form-control" type="text">
                        <span class="text-danger">{{ sentTicket == true ? '' : errors[0] }}</span>
                    </ValidationProvider>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-12">
                  <label for="details">Message <span class="text-danger">*</span></label>
                    <ValidationProvider name="details" rules="required" v-slot="{ errors }">
                        <ckeditor :editor="editor" :config="editorConfig" v-model="form['details']"></ckeditor>
                        <span class="text-danger">{{ sentTicket == true ? '' : errors[0] }}</span>
                    </ValidationProvider>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-12">
                  <label for="subject">Attachment (Only JPG and PNG are accepted. Maximum file size allowed is 5MB)</label>
                    <ValidationProvider name="attachment" rules="size:5000|mimes:image/*" v-slot="{validate,errors }">
                        <input type="file" ref="attachment" name="attachment" class="form-control" @change="validate">
                        <span class="text-danger">{{ sentTicket == true ? '' : errors[0] }}</span>
                    </ValidationProvider>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-2">
                  <vue-recaptcha sitekey="6Ld0Es8ZAAAAAEc9YEvWz4ejWF7l7vTutpBoNFSB" @expired="recaptchaExpired" @verify="verifyRecapcha" :loadRecaptchaScript="true"></vue-recaptcha>
                  <span class="text-danger" v-if="alertRecaptcha == true">Recaptcha is required</span>
                </div>
              </div>
              <div class="row" v-if="showSuccessAlert == true">
                <div class="form-group col-md-12">
                    <div class="alert alert-success" role="alert">
                      Thank you for getting in touch! We have your inquiry and our customer service team will get back to you within 3 business days.
                    </div>
                </div>
              </div>
              <div class="row" v-if="errorSend == true">
                <div class="form-group col-md-12">
                    <div class="alert alert-danger" role="alert">
                      There was an error trying to send your message. Please try again later or you can reach out to us at <a class="link-error" href="tel:+6564252500">+65 6425 2500</a>.
                    </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-12">
                  <button class="btn btn-lg btn-info" :disabled="loading == true ? true : false">Send <i v-if="loading == true" class="fa fa-fas fa-spinner fa-spin"></i></button>
                </div>
              </div>
            </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email, numeric, image, mimes,min,max,size, alpha_spaces, alpha_dash, regex } from 'vee-validate/dist/rules';
import {VueTelInput} from 'vue-tel-input';
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import VueRecaptcha from 'vue-recaptcha';


extend('required', {
    ...required,
    message: 'This field is required'
});
extend('min', {
    ...min
});
extend('max', {
    ...max
});
extend('email', {
    ...email,
    message: 'Please provide a valid email address'
});
extend('numeric', {
    ...numeric,
    message: 'The value must be numeric'
});
extend('image', {
    ...image,
    message: 'This field should contain image'
});
extend('mimes', {
    ...mimes,
    message: 'This image should jpg / png'
});
extend('size', {
    ...size,
    message: 'Maximum file size allowed is 5MB.'
});
extend('alpha_spaces', {
    ...alpha_spaces,
    message: 'Please provide a valid value.'
});
extend('alpha_dash', {
    ...alpha_dash,
    message: 'Please provide a valid value.'
});
extend('regex', {
    ...regex,
    message: 'Please provide a valid value.'
});
export default {
  name: 'App',
  components :{
      ValidationProvider,
      ValidationObserver,
      ckeditor: CKEditor.component,
      VueTelInput,
      VueRecaptcha
  },
  data() {
    return {
      form: {},
      phoneCode: 65,
      csroute: 'https://sg-scb-css.cprv.io/',
      // csroute: 'https://sg-scb-css-uat.cprv.io/',
      helptopics: {},
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [ 'Heading','Bold', 'Italic','|', 'Underline','NumberedList','BulletedList','Outdent','Indent','Link']
      },
      //live
      hardcodedHelpTopic: [
        {id:8, topic: 'General Feedback'},
        {id:41, topic: 'Membership'},
        {id:42, topic: 'Buy Tickets'},
        {id:43, topic: 'Business Development (Sales)'},
        {id:49, topic: 'Omni Theatre'},
        {id:60, topic: 'KidsSTOP'},
        {id:61, topic: 'School Booking and Services'},
        {id:86, topic: 'Marketing and Communications'},
        {id:87, topic: 'Website Feedback'},
        {id:96, topic: 'Snow City'}
      ],
      // staging
      // hardcodedHelpTopic: [
      //   {id:8, topic: 'General Feedback'},
      //   {id:54, topic: 'Membership'},
      //   {id:55, topic: 'Buy Tickets'},
      //   {id:56, topic: 'Business Development Sales'},
      //   {id:62, topic: 'Omni Theatre'},
      //   {id:73, topic: 'KidsSTOP'},
      //   {id:74, topic: 'School Booking and Services'},
      //   {id:99, topic: 'Marketing and Communications'},
      //   {id:100, topic: 'Website Feedback'},
      //   {id:109, topic: 'Snow City'}
      // ],
      recaptchaValid: false,
      sentTicket: false,
      showSuccessAlert: false,
      alertRecaptcha: false,
      token: '',
      loading: false,
      errorSend: false
    }
  },
  computed: {
    getDetails: function()
    {
      return this.form.details;
    }
  },
  mounted() {
    document.querySelector(".vti__input").addEventListener("keypress", function (evt) {
      if (evt.which < 48 || evt.which > 57)
      {
          evt.preventDefault();
      }
    });

    this.getHelpTopic();
    this.getToken();
  },
  methods : {
    countryChanged(country) {
      this.phoneCode = country.dialCode;
    },
    getToken: async function()
    {
      const gt = await axios.get(this.csroute+'api/get-form-token');
      this.token = gt.data;
    },
    getHelpTopic: async function()
    {
      const rht = await axios.get(this.csroute+'api/get-helptopic/all');
      this.helptopics = rht.data;
    },
    onSubmit: async function() {
        const validate = await this.$refs.form.validate();
        if(validate == false ){
            this.alertRecaptcha = true;
        } else if(validate == true && this.token != '') { 
            if (this.recaptchaValid == true) {
              try {
                this.sentTicket = false;
                this.loading = true;
                this.alertRecaptcha = false;
                // post form
                let fd = new FormData();
                fd.append('_token',this.token);
                fd.append('first_name',this.form.first_name);
                fd.append('last_name',this.form.last_name);
                fd.append('Email',this.form.email);
                fd.append('mobile',this.form.hpno.match(/\d/g).join("").replace(/^0+/, ''));
                fd.append('Code',this.phoneCode);
                fd.append('helptopic',this.form.helptopic);
                fd.append('Subject',this.form.subject);
                fd.append('Details',this.form.details);
                if (this.$refs.attachment.files[0] != undefined) {
                  fd.append('attachment',this.$refs.attachment.files[0]);
                }
                
                const pf = await axios.post(this.csroute+'api/post-iframe-form',fd,{
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                });
                
                this.loading = false;
                if(pf.data.success == true){
                  this.form = {};
                  this.$refs.attachment.value=null;
                  this.sentTicket = true;
                  this.showSuccessAlert = true;
                  this.errorSend = false;
                  let self = this;
                  setTimeout(function() { self.showSuccessAlert = false; }, 30000);
                } else {
                  this.errorSend = true;
                  this.loading = false;
                }                
              } catch (error) {
                this.errorSend = true;
                this.loading = false;
              }
            } else {
              this.alertRecaptcha = true;
              this.errorSend = false;
            }
        }
    },
    verifyRecapcha: function(response)
    { 
      if (response) {
        this.recaptchaValid = true;
      } else {
        this.alertRecaptcha = true;
      }
    },
    recaptchaExpired: function()
    {
      this.recaptchaValid = false;
      this.alertRecaptcha = true;
    }
  }
}
</script>
<style scoped>
  .link-error {
    color: #721c24 !important;
  }
</style>
